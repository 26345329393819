var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout"
  }, [_c('header', [_c('Header')], 1), _c('main', {
    staticClass: "main"
  }, [_c('router-view')], 1), _c('footer', [_c('Footer')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "dark-theme"
  }, [_c('p', [_vm._v(" 安徽省人工智能学会 版权所有 ")]), _c('p', [_vm._v(" 地址：合肥市经开区丹霞路合肥工业大学翡翠湖校区 翡翠科技楼705 邮编：230009 传真：0551-62902373 邮箱：aaai_ah@163.com ")])])]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.array.push.js";
export default {
  name: 'studentHeader',
  data() {
    return {
      user: null
    };
  },
  components: {},
  created() {
    try {
      this.user = JSON.parse(window.localStorage.getItem('user'));
    } catch (e) {
      this.user = null;
    }
    console.log('🚀 ~ computed ~ this.user:', this.user);
  },
  methods: {
    loginOut() {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      this.user = null;
      this.$router.push({
        path: '/student/login'
      });
    }
  }
};